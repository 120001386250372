import {
    Jugo1,
    Jugo2,
    Jugo3,
    Jugo4,
    Jugo5,
    Jugo6,
    Jugo7,
    Jugo9,
    Jugo10,
    Jugo11,
    JugoModal1,
    JugoModal2,
    JugoModal3,
    JugoModal4,
    JugoModal5,
    JugoModal6,
    JugoModal7,
    JugoModal9,
    JugoModal10,
    JugoModal11,
} from "../images/productos/aguasyjugos"

import {
    PDF1,
    PDF2,
    PDF3,
    PDF4,
    PDF5,
    PDF6,
    PDF7,
    PDF8,
    PDF9,
    PDF10,
    PDF11,
} from "../../static/20-jugos/index"


export const Jugos = [

    {
        pdfDownload: PDF2,
        size: 0,
        name: "",
        src: Jugo2,
        srcModal: JugoModal2,
    }, {
        pdfDownload: PDF3,
        size: 0,
        name: "",
        src: Jugo3,
        srcModal: JugoModal3,
    }, {
        pdfDownload: PDF5,
        size: 0,
        name: "",
        src: Jugo5,
        srcModal: JugoModal5,
    }, {
        pdfDownload: PDF6,
        size: 0,
        name: "",
        src: Jugo6,
        srcModal: JugoModal6,
    },
    //  {
    //     pdfDownload: PDF8,
    //     size: 0,
    //     name: "",
    //     bottledata: {
    //         subtitle: "",
    //         description: "",
    //     },
    //     src: Jugo8,
    //     srcModal: JugoModal8,
    //     color: {
    //         white: true,
    //         green: true,
    //         darkGreen: false,
    //         yellow: false,
    //         lightGreen: false,
    //     },
    // },
    {
        pdfDownload: PDF9,
        size: 0,
        name: "",
        src: Jugo9,
        srcModal: JugoModal9,
    }, {
        pdfDownload: PDF10,
        size: 0,
        name: "",
        src: Jugo10,
        srcModal: JugoModal10,
    },
    // {
    //     pdfDownload: PDF11,
    //     size: 0,
    //     name: "",
    //     bottledata: {
    //         subtitle: "",
    //         description: "",
    //     },
    //     src: Jugo11,
    //     srcModal: JugoModal11,
    // },
]