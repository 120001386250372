import React from "react";

import Products from "../products";
import ProductCard from "../../components/ProductCards/ProductCards";
import SEO from "../../components/SEO/Seo";

import { Jugos } from "../../data/jugos";

const EnvasesAlimentos = () => {
  return (
    <>
      <SEO
        title="Blue Sky SA -Envases de vidrio - Botellas de jugo - Jugos - Gaseosas. Argentina."
        keywords="Blue Sky SA ,Envases de vidrio , Botellas de jugo , Jugos , Gaseosas, Argentina, Frascos de vidrio, Botellas de vidrio para Jugo, Agua y Leche, Lisas y decoradas, Vidrio blanco transparente, 200, 250, 350, 500, 750 y 1000 ml"
        description="Blue Sky SA -Envases de vidrio - Botellas de jugo - Jugos - Gaseosas. Argentina. Frascos de vidrio. Botellas de vidrio para Jugo, Agua y Leche. Lisas y decoradas. Vidrio blanco transparente. 200, 250, 350, 500, 750 y 1000 ml"
      />
      <Products subtitle="Aguas y Jugos">
        <ProductCard data={Jugos} />
      </Products>
    </>
  );
};

export default EnvasesAlimentos;
